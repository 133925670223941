.container {
  max-width: 1300px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 15px;
  text-align: center;
}

.bg {
  overflow: hidden;
  background-color: rgb(248, 248, 251);
}

.notBg {
  overflow: hidden;
}
