@mixin ft-text {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  overflow: hidden;
  background-color: #2ca2b4 0%;
}

@mixin tf-text-color-primary {
  background-image: linear-gradient(
    90deg,
    #22a5b9,
    #5598de 20%,
    #7f87ff 45%,
    #4784c0 70%,
    #0c6ac9
  );
}

@mixin tf-text-color-success {
  background-image: linear-gradient(
    90deg,
    #22a5b9,
    #28b148 20%,
    #56a625 45%,
    #4784c0 70%,
    #2ccab2
  );
}

@mixin tf-text-color-info {
  background-image: linear-gradient(90deg, #2091f6, #21b568);
}

@mixin tf-text-color-black {
  background-image: linear-gradient(180deg, #000000, #888888);
}

.title {
  font-size: 48px;
  font-weight: 800;
}

.highlightBlack {
  @include ft-text;
  @include tf-text-color-black;
}

.highlightBlue span {
  color: #1976d2;
  @include ft-text;
  @include tf-text-color-primary;
}

.highlightInfo span {
  color: #1976d2;
  @include ft-text;
  @include tf-text-color-info;
}

.highlightGreen span {
  color: #12b442;
  @include ft-text;
  @include tf-text-color-success;
}

.container {
  max-width: 1300px;
  margin: auto;
  margin-top: 100px;
  padding: 15px;
  text-align: center;
}

.indexImg {
  // text-align: right;
  // position: absolute;
  // right: 0;
  // top: 0;
  // z-index: 1;
}

.smallContainer {
  margin-top: 20px;
  max-width: 1100px;
  margin: auto;
  text-align: left;
}

.pageImg {
  // margin-top: 64px;
  // max-width: 600px;
}

.pageLeftText {
  max-width: 520px;
  margin: auto;
}

.script {
  display: block;
  background-color: #333333;
  color: #eee;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.sponsorListContainer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 620px;
  padding: 12px;
}

.featureItem {
  text-align: center;
  font-size: 18px;
}

.featureItem svg {
  text-align: center;
  font-size: 60px;
  margin: auto;
}

.indexBackGround {
  z-index: -1;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

.ball {
  border-radius: 50%;
  background: linear-gradient(45deg, #5afff9, #78ffac);
  box-shadow: 0 0 10px #93ffcc;
  filter: blur(30px);
}

.indexTitle {
  display: block;
}

.titleATag {
  a {
    color: rgb(31, 120, 222);
  }
  a:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
}

.descWrapper {
  span {
    text-decoration: underline dashed;
    text-underline-offset: 4px;
  }
}
