.panelCard {
  --card-shadow-color: rgb(222, 222, 222);
  /* border: 1px solid var(--card-shadow-color); */
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 1px 2px 1px var(--card-shadow-color);
  overflow: hidden;
  transition: all 0.4s;
}

.panelCard:hover{
  box-shadow: 0 1px 12px rgb(172, 169, 169)
}
