img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  user-select: none;
}
.imageWrapper {
  /* margin: 12px; */
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 16px #0000006d;
  display: inline-block;
}
.imageWrapper img {
  vertical-align: middle;
  max-width: 1100px;
}

.imageWrapperAction {
  transition: all 0.8s;
}

.imageWrapperAction:hover {
  transform: scale(1.01);
}

.pageTitle {
  /* font-weight: 400 !important; */
}

.dance {
  animation: heart 1s ease-in-out 0s infinite alternate;
}

.dance-2 {
  animation: heart 1.4s ease-in-out 0s infinite alternate;
}

.scaleAnimation {
  animation: scaleAnimation 1s ease-in-out 0s infinite alternate;
}

.shakeAnimation {
  animation: shake 1.2s ease-in-out 1s infinite alternate;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes heart {
  from {
    transform: translate(0, -5px);
  }
  to {
    transform: translate(0, 1px);
  }
}

a {
  text-decoration: none;
}

/* @keyframes tf-action-1 {
  from {
    background-image: linear-gradient(90deg, #2c7ac7, #267cd3 50%, #2174c7);
  }

  to {
    background-image: linear-gradient(90deg, #ff4400, #ff4400 50%, #f69d7c);
  }
} */

.tf-text-1 {
  background-image: linear-gradient(
    90deg,
    #2ca2b4,
    #5598de 24%,
    #7f87ff 45%,
    #f65aad 76%,
    #ec3d43
  );
}
.tf-text-2 {
  background-image: linear-gradient(
    90deg,
    #2ca2b4,
    #51d146 24%,
    #7f87ff 45%,
    #f65aad 76%,
    #4885ef
  );
}

.tf-text-3 {
  color: black;
  /* background-image: linear-gradient(180deg, #000000, #363636 50%, #949494); */
  background-image: linear-gradient(
    90deg,
    #1d8fa1,
    #508dcf 20%,
    #7f87ff 45%,
    #4784c0 70%,
    #0c6ac9
  );
  transition: all 2s;
}

.tf-font {
  font-weight: 600;
  font-size: 3rem;
}
.tf-text-wrapper {
  will-change: background-image;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  overflow: hidden;
  background-color: #2ca2b4 0%;
  display: inline-block;
  width: 100%;
}

.text-underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 66%;
  left: -0.1em;
  right: -0.1em;
  bottom: 0;
  transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1);
  background-color: rgba(79, 192, 141, 0.5);
}
.text-underline {
  position: relative;
}

.text-child-underline span {
  position: relative;
}
.text-child-underline span:after {
  content: "";
  position: absolute;

  top: 66%;
  left: -0.1em;
  right: -0.1em;
  bottom: 0;
  transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1);
  background-color: rgba(79, 192, 141, 0.5);
}

.transition-6 {
  transition: all 0.6s;
}

.transition-4 {
  transition: all 0.4s;
}

.transition-2 {
  transition: all 0.2s;
}

.code-area {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}
