.headerWrapper {
  height: 80px;
  line-height: 80px;
  overflow: hidden;
  // position: fixed;
  // left: 0px;
  // right: 0px;
  // top: 0px;
  // z-index: 999;
}

.headerWrapperTop {
  background: rgba(255, 255, 255, 0);
}

.headerWrapperExtend {
  background-color: #313132;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 999;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  animation: all 0.4s;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
}

.headerContainer {
  margin: auto;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.headerContainer * {
  // color: #f5f5f7 !important;
}

.headerItem {
  display: flex;
  text-align: left;
  align-items: center;
}

.headerItem > div {
  margin: 0px 12px;
}

.headerTopInfoContainer {
  a {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }

  a:hover {
    text-decoration: underline;
  }

  span {
    opacity: 0.88;
    color: white;
    text-decoration: underline;
  }

  color: black;
  background-color: #c6b050;
  background: linear-gradient(90deg, #3ca877, #1fa861);
  border-bottom: 1px solid #349267;
  border-top: 1px solid #349267;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  padding: 8px 0px;
}
