.wrapper {
  background: rgba(0, 0, 0, 0.8);
  color: #f5f5f7;
  overflow: hidden;
}

.container {
  max-width: 1300px;
  margin: 90px auto;
  text-align: center;
  padding: 0px 15px;
}
